var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-container__wrap"},[_c('div',{staticClass:"treasures-1-margin"},[_c('div',{staticClass:"treasures-1-container"},[_c('div',{staticClass:"treasures-1-inner"},[_vm._m(0),_c('div',{staticClass:"smew"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true},on:{"loadeddata":_vm.videoLoaded}},[_c('source',{attrs:{"src":"video/smew.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/smew.webm","type":"video/webm"}})]),(!_vm.isLoadingOne)?_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"img/smew.webp"}}),_c('img',{attrs:{"loading":"lazy","src":"img/smew.png","alt":""}})]):_vm._e()]),_c('div',{staticClass:"spider"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/spider.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/spider.webm","type":"video/webm"}})])]),_c('div',{staticClass:"kutora"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/kutora.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/kutora.webm","type":"video/webm"}})])]),_c('div',{staticClass:"owl"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/owl.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/owl.webm","type":"video/webm"}})])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_1"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Луток")]),_c('div',{staticClass:"treasures-1-item__descr"},[_c('p',{staticClass:"mb-10"},[_vm._v(" Эта утка — символ «Пасвика». Её изображение с 2021 года находится на логотипе заповедника. ")]),_c('p',[_vm._v(" В других частях России луток довольно редок, но в «Пасвике» это один из самых массовых и типичных видов. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_2"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Жемчужница европейская")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Рекордсмен-долгожитель среди пресноводных беспозвоночных: эти моллюски могут прожить свыше 100, а по некоторым данным, более 200 лет. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_3"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Гапломитриум Хукера")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" В Мурманской области известно всего 10 местонахождений этого мха, причём каждое из них крайне малочисленное — не более 30 особей. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_4"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Акулепейра лапландская")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Паук-кругопряд, обитающий на территории Скандинавского полуострова и в Мурманской области. На территории заповедника «Пасвик» удалось встретить всего двух самок. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_5"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Обыкновенная кутора")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Эта крупная землеройка — отличный пловец, поэтому охотится не только на насекомых, но также на рыб и птенцов водоплавающих птиц. В её слюне содержится яд, обладающий парализующим действием. Благодаря этому кутора может делать запасы из живых, но обездвиженных животных. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_6"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Бородатая неясыть")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Один из самых крупных видов неясытей. В отличие от многих других сов активна не только по ночам, но и в светлое время суток. ")])])
}]

export { render, staticRenderFns }