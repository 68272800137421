var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-container__wrap"},[_c('div',{staticClass:"treasures-2-margin"},[_c('div',{staticClass:"treasures-2-container"},[_c('div',{staticClass:"treasures-2-inner"},[_vm._m(0),_c('div',{staticClass:"lizard"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true},on:{"loadeddata":_vm.videoLoaded}},[_c('source',{attrs:{"src":"video/lizard.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/lizard.webm","type":"video/webm"}})]),(!_vm.isLoadingOne)?_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"img/lizard.webp"}}),_c('img',{attrs:{"loading":"lazy","src":"img/lizard.png","alt":""}})]):_vm._e()]),_c('div',{staticClass:"swan"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true},on:{"loadeddata":_vm.videoLoadedTwo}},[_c('source',{attrs:{"src":"video/swan.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/swan.webm","type":"video/webm"}})]),(!_vm.isLoadingTwo)?_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"img/collage-kola-swan.webp"}}),_c('img',{attrs:{"loading":"lazy","src":"img/collage-kola-swan.png","alt":""}})]):_vm._e()]),_c('div',{staticClass:"shrike"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/shrike.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/shrike.webm","type":"video/webm"}})])]),_c('div',{staticClass:"deep"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"https://www.dropbox.com/scl/fi/06c8xlgu43sqhvnqpw8uk/deep-big.mov?rlkey=3q9y6yv0o6xx69y8nmqkrisy2&st=kdczywm0&dl=0","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/deep-big.webm","type":"video/webm"}})])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_1"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Росянка")]),_c('div',{staticClass:"treasures-2-item__descr"},[_c('p',[_vm._v(" Листья этого растения покрыты капельками клейкого сока. Садящиеся на них насекомые оказываются в ловушке: они прилипают и уже не могут улететь. После этого росянка закручивает лист, полностью обволакивая свою жертву, и в течение нескольких дней переваривает добычу. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_2"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Живородящая ящерица")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Благодаря своей устойчивости к низким температурам этот вид ящерицы может обитать даже в районе Полярного круга. Зимуют эти рептилии под землёй, забираясь в укрытия на глубину до 40 см. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_3"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Калипсо луковичная")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Один из 10 видов орхидей, произрастающих на территории заповедника. Своё название растение получило в честь нимфы из древнегреческой мифологии — Калипсо. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_4"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Лебедь-кликун")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" В Лапландский заповедник эти птицы прилетают с мест зимовки в марте — начале апреля. Холода им не страшны — главное, чтобы была открытая вода, где лебеди могут кормиться. Эти птицы моногамны и образуют пару на всю жизнь. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_5"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Обыкновенный серый сорокопут")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Эта крупная певчая птица — безжалостный охотник. Основу её рациона составляют мышевидные грызуны, ящерицы и крупные насекомые. Пойманную добычу сорокопут может запасать впрок, нанизывая жертв на острые сучки и шипы. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_6"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Дикий северный олень")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Единственный вид оленей, у которого рога имеют как самцы, так и самки. В Лапландском заповеднике обитает примерно 1000 этих животных. Чтобы пережить суровые зимы, олени на время сбиваются в стада по 100 и более особей. ")])])
}]

export { render, staticRenderFns }